import "./index.css";
import React, {Suspense, useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {Loader2} from "lucide-react";

//Constant config variable imports
import {INTERACTION_TYPE, MESSAGE_TYPES} from "/src/utils/constant.js"

// API imports
import {
    getBrandingDetailsAPI,
    getDownloadIconAPI,
    getInterfaceSession,
    getProgressStateAPI,
    getReplaySession,
} from "./services/api/api.js";
import initializeSocket from "./socket.js";
import {axiosManager} from "./utils/axiosmanager.js";

// Redux actions and selectors
import {getUserInitialize} from "./slices/actions.js";
import {
    setAppLoader,
    setBlockError,
    setBrandIcon,
    setBrandingData,
    setButtonInputHint,
    setComponentData,
    setErrorToastMessage,
    setLoader,
    setProgressState,
    setReplayComponents,
    setSessionData,
    setSocket,
    setStartInterfaceBlockID,
    setTraceData,
} from "./slices/slices.js";

// Components
const ProductionItem = React.lazy(() => import("./views/pages/ProductItem/ProductItem.jsx"));
const Error = React.lazy(() => import("./views/components/ErrorComponent/ErrorComponent.jsx"));

const LoadingFallback = () => (
    <div className="flex items-center justify-center w-full h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"/>
    </div>
);

const LoadingScreen = () => (
    <div className="flex h-full flex-col items-center justify-center">
        <Loader2 className="animate-spin"/>
        <p>Please wait...</p>
    </div>
);

function App() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    // Redux selectors
    const {
        initialized,
        components,
        replayComponentsData,
        interfaceSession,
        appLoader,
        socket,
    } = useSelector((state) => state.userSlice);

    const {token, error, loader} = useSelector((state) => state.credentialSlice);

    const counter =  useRef(3);

    // Initialize app with signature
    useEffect(() => {
        const signature = searchParams.get("signature");
        if (signature && !initialized && !token) {
            dispatch(getUserInitialize(signature));
        }

        return () => {
            if (socket) socket.close();
        };
    }, [dispatch, initialized, searchParams, socket]);

    // Socket event handler
    const handleSocketEvent = useCallback((eventName, ...args) => {
        if (eventName !== "INTERFACE_OUTGOING_MESSAGE") return;

        const [eventData] = args;
        const {data: {message, interface_block_id} = {}} = eventData || {};

        dispatch(setLoader(false));

        // Skip processing for specific block types
        if (
            message?.block_type === "ternary_router_block" ||
            message?.block_type === "parse_date_time_block" ||
            message?.element_type === "WAIT_MESSAGE"
        ) {
            return;
        }

        //Handle error toast message
        if (message?.element_type == 'ERROR_TOAST_MESSAGE') {
            dispatch(setErrorToastMessage(message?.text));
            return;
        }

        // Handle terminal states

        // Handle trace messages
        if (message?.message_type === MESSAGE_TYPES.TRACE) {
            if (message?.block_current_status === "FAILED") {
                dispatch(setAppLoader(false));
                dispatch(setBlockError(message));
            }
            dispatch(setTraceData(args));
        }
        if (
            message?.message_type === MESSAGE_TYPES.TERMINATE
        ) {
            dispatch(setAppLoader(false));
            handleTerminalState(message, interfaceSession);
        }

        //Handle Heartbeat
        if (message?.message_type == MESSAGE_TYPES.HINT && message?.interaction_type === INTERACTION_TYPE.HEARTBEAT) {
            socket.emit('interface_interaction', {
                interface_block_id: interface_block_id,
                interaction_type: message?.interaction_type
            })
        }

        // Handle hint messages
        if (message?.message_type == MESSAGE_TYPES.HINT && message?.interaction_type == INTERACTION_TYPE.BUTTON_INPUT) {
            dispatch(setButtonInputHint(message));
            return;
        }

        if (message?.message_type === MESSAGE_TYPES.HINT &&
            message?.interaction_type === "START_BUTTON") {
            dispatch(setStartInterfaceBlockID(interface_block_id));
        }
        if (message?.message_type !== MESSAGE_TYPES.TRACE) {
            dispatch(setComponentData(args));
            dispatch(setAppLoader(false));
        }

    }, [dispatch, interfaceSession, socket]);

    // Initialize socket connection
    useEffect(() => {
        if (token && !socket) {
            const newSocket = initializeSocket(token);
            dispatch(setSocket(newSocket));
        }
    }, [token, socket, dispatch]);

    // Setup socket listeners
    useEffect(() => {
        if (!socket) return;

        socket.onAny(handleSocketEvent);
        socket.on("disconnect", () => {
            console.log("Socket disconnected:", socket.id);
        });

    }, [socket]);

    // Initialize API data
    useEffect(() => {
        if (!token) return;

        axiosManager.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const fetchInitialData = async () => {
            try {
                const [progressState, sessionData, brandingDetails, brandIcon] = await Promise.allSettled([
                    getProgressStateAPI(),
                    getInterfaceSession(),
                    getBrandingDetailsAPI(),
                    getDownloadIconAPI(),
                ]);

                dispatch(setProgressState(progressState.value.data?.progress_state?.block_states));
                dispatch(setSessionData(sessionData.value.data));
                dispatch(setBrandingData(brandingDetails.value.data));

                // Handle brand icon
                const blob = new Blob([brandIcon.value.data], {type: "image/x-icon"});
                const objectURL = URL.createObjectURL(blob);
                dispatch(setBrandIcon(objectURL));
            } catch (error) {
                console.error("Error fetching initial data:", error);
                dispatch(setLoader(false));
            }
        };

        fetchInitialData();
    }, [token, dispatch]);

    // Handle replay session
    useEffect(() => {
        if (token && components?.length) return;

        if (!components?.length  && token && counter.current==0) {

            const fetchReplaySession = async () => {
                try {
                    const response = await getReplaySession();
                    const {messages = []} = response.data;

                    const startButton = messages.find(
                        item => item?.data?.interface_block_type === 'on_start_widget_block'
                    );

                    if (startButton) {
                        dispatch(setStartInterfaceBlockID(startButton?.data?.interface_block_id));
                    }

                    const {traceData, componentData} = processMessages(messages);
                    dispatch(setReplayComponents(response.data));
                    dispatch(setTraceData(traceData));
                    dispatch(setComponentData(componentData));
                } catch (error) {
                    console.error("Error fetching replay session:", error);
                } finally {
                    dispatch(setLoader(false));
                }
            };
            fetchReplaySession();
        }
        return()=>{
            counter.current = counter.current-1;
            if(components?.length && counter.current ==0){
                counter.current = 3;
            }
        }
    }, [components, token, counter.current]);

    console.log("Counter", counter.current);

    return (
        <Suspense fallback={<LoadingFallback/>}>
            <div className="max-w-lg sm:w-full bg-white border mx-auto sm:py-0 py-0 h-full">
                {loader && !components?.length ? (
                    <LoadingScreen/>
                ) : error ? (
                    <Error/>
                ) : (
                    <ProductionItem/>
                )}
            </div>
        </Suspense>
    );
}

// Helper functions
const processMessages = (messages) => {
    const traceData = [];
    const componentData = [];

    messages.forEach((item) => {
        const {data: {message, interface_block_type} = {}} = item;

        if (message?.message_type === "TRACE") {
            if (
                !["INITIALIZED", "SUSPENDED", "RESCUED"].includes(message?.block_current_status) &&
                interface_block_type !== "router_block"
            ) {
                componentData.push(item);
                traceData.push(item);
            }
        } else if (message?.element_type !== "WAIT_MESSAGE") {
            componentData.push(item);
        }
    });

    return {traceData, componentData};
};



const handleTerminalState = (message, interfaceSession) => {
    const status = message?.message_type === "TERMINATE" && !["FAILED", "DEAD"].includes(message?.block_current_status) ? "completed" :
        ["FAILED", "DEAD"].includes(message?.block_current_status) ? "failed" : "";
console.log("Sattus", status);
    if (interfaceSession?.redirect_url) {
        const separator = interfaceSession.redirect_url.includes('?') ? '&' : '/?';
        const redirectUrl = `${interfaceSession.redirect_url}${separator}status=${status}`;

        setTimeout(() => {
            window.open(redirectUrl, "_self");
        }, 300);
    }

    if (message?.message_type === "TERMINATE") {
        const url = window.location !== window.parent.location ?
            document.referrer : document.location.href;
        window.parent.postMessage("close-iframe", url);
        localStorage.clear();
    }
};

export default App;
