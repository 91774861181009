import "./sentry_trace.js";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { Provider } from "react-redux";
import { store } from "./store.js";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Error from "./views/components/ErrorComponent/ErrorComponent.jsx";
import { jwtDecode } from "jwt-decode";
import PassportVerification from "./views/presets/preset1/wizard-panels/PassportVerificationWizard.jsx";


const signature = window.location.search?.split("?signature=")?.[1] || "";

if (signature) {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
      <Provider store={store}>
         <App />
      </Provider>
    </BrowserRouter>
  );
} else {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
      <Provider store={store}>
         <Error/>
      </Provider>
    </BrowserRouter>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept("./App", () => {
    return require("./App").default;
  });
}
