export const MESSAGE_TYPES = {
    HINT: "HINT",
    TRACE: "TRACE",
    ELEMENT: "ELEMENT",
    HALT: "HALT",
    HINT: "HINT",
}

//Socket Interaction Type to render UI interaction element
export const INTERACTION_TYPE = {
    NUMERIC_INPUT: "NUMERIC_INPUT",
    TEXT_INPUT: "TEXT_INPUT",
    OTP_INPUT: "OTP_INPUT",
    SELECT_INPUT: "SELECT_INPUT",
    DATE_INPUT: "DATE_INPUT",
    DATETIME_INPUT: "DATETIME_INPUT",
    CAMERA_IMAGE_INPUT: "CAMERA_IMAGE_INPUT",
    FILE_INPUT: "FILE_INPUT",
    BUTTON_INPUT: "BUTTON_INPUT",
    HEARTBEAT: "HEARTBEAT"
}
