import React from "react";
import errorImage from "/public/assets/failed.svg";
import {useSelector} from "react-redux";

export default function Error() {
    const {error} = useSelector(state => state.credentialSlice);
    return (
        <div className="w-full h-full flex flex-col  justify-center items-center" role="alert">
            <div className={'w-[328px] h-[187px] mx-auto my-[24px]'}>
                <div className={'w-[179px] h-[179px] mx-auto'}>
                    <img src={errorImage} className=" w-full h-full object-contain"
                         alt="Error illustration"/>
                </div>

            </div>

            <h1 className="text-[24px] leading-[24px] text-center font-[500] text-[#000000]">Oops! Something went
                wrong!</h1>
            <span className="text-[14px] my-[8px] text-[#00000099] leading-[24px] font-[400] text-center">{error}</span>
        </div>
    );
}
